

.b-4{
    width: fit-content;
    background-color: darkblue;
    color: white;
    font-size: 20px !important;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top: 15px;
    cursor: pointer;
    
}


@media (max-width: 600px){
    .b-4{
        width: fit-content;
        background-color: darkblue;
        color: white;
        font-size: 16px !important;
        padding: 5px;
        padding-left: 14px;
        padding-right: 14px;
        border-radius: 10px;
        margin-bottom: 10px;
        margin-top: 15px;
        cursor: pointer;
        
    }
}