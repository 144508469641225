.header1{
   color: white;
   background-color: darkblue;
   padding: 5px;
   margin: 5px;
   border-radius: 5px;
   align-items: center;

}

.off{
   margin-left: 10px;
}

.spinner-border {
   border-width: 0.25em;
   border-style: solid;
   border-color: rgba(0, 0, 0, 0.1);
   border-top-color: currentColor;
   border-radius: 50%;
   animation: spin 1s linear infinite;
 }
 
 @keyframes spin {
   to { transform: rotate(360deg); }
 }
 