/* ModalGroup.css */
.spinner{
    color: white;
    width: fit-content;
    padding: 5px;
    margin: 5px;
}
  
.bt-p{
    border: none;
    background-color: darkblue;
    color: white;
    border-radius: 10px;
    padding: 5px;
    
}
