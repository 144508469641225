/* Input.css */

/* Default styles */
.card-container {
  margin-bottom: 16px;
  padding: 16px;
  width: 40%;
  margin: auto;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  background-color: #fff; 
  border: 1px solid #ddd; 
  margin-top: 30px;
  overflow-x: hidden;
}

.input-label {
  display: block;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  color: darkblue;
  text-align: left;
  width: 70%;
}

.h4-label {
  color: darkblue;
  text-align: center;
  font-size: 30px;
}

.input-field {
  width: 70%;
  padding: 17px;
  border: 1px solid #0a0450;
  border-radius: 5px;
  font-size: 18px;
  box-sizing: border-box;
  text-align: left;
}

.input-field:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.mark-label {
  color: red;
}

.over {
  display: flex;
  flex-direction: row;
  width: 60%;
  padding: 10px;
  margin: 10px;
}

.first-b {
  color: red;
  background-color: white;
  border: 0.5px solid rgb(215, 205, 205);
  cursor: pointer;
  margin-right: 10px;
  border-radius: 5px;
  padding: 10px;
  font-size: 20px;
}

.second-b {
  background-color: #0a0450;
  color: white;
  border: 0.5px solid rgb(215, 205, 205);
  cursor: pointer;
  margin-left: 10px;
  border-radius: 5px;
  padding: 10px;
  font-size: 20px;
}

.log-im {
  width: 60%;
}

.ov-al {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.fl-1 {
  width: 40%;
}

.fl-2 {
  width: 60%;
}

.p-1 {
  color: darkblue;
  font-size: large;
  padding: 10px;
  margin: 10px;
}

.fatey {
  position: relative;
}


@media (max-width: 600px) and (orientation: landscape) {
  .card-container {
    overflow-x: hidden;
    width: 98%; 
  }

  .input-label {
    font-size: 14px; 
  }

  .input-field {
    font-size: 14px; 
  }

  .h4-label {
    font-size: 20px; 
  }
}

@media (max-width: 600px) {
  .card-container {
    width: 90%; /* Adjust width for smaller screens */
    margin-top: 20px; 
  }

  .input-label {
    font-size: 10px; /* Smaller font size for mobile */
  }

  .input-field {
    font-size: 14px; 
    width: 100% !important;
    /* Adjust input field font size for mobile */
  }

  .h4-label {
    font-size: 20px; /* Smaller font size for h4-label on mobile */
  }
  .first-b {
    color: red;
    background-color: white;
    border: 0.5px solid rgb(215, 205, 205);
    cursor: pointer;
    margin-right: 10px;
    border-radius: 5px;
    padding: 5px;
    font-size: 14px;
  }
  
  .second-b {
    background-color: #0a0450;
    color: white;
    border: 0.5px solid rgb(215, 205, 205);
    cursor: pointer;
    margin-left: 10px;
    border-radius: 5px;
    padding: 5px;
    font-size: 14px;
  }
  .p-1 {
    color: darkblue;
    font-size: large;
    padding: 5px;
    margin: 5px;
  }
  .fl-1 {
  display: none;
  }
  .fl-2{
    width: 98%;
  }
  
}
