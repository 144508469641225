/* Toast.css */
.toast {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #333;
    color: rgba(255, 255, 255, 0.375);
    padding: 10px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  
  .toast-close {
    background: transparent;
    border: none;
    color: rgb(116, 5, 5);
    font-size: 18px;
    cursor: pointer;
  }
  
  .toast p {
    margin: 0;
  }
  
       
  